<template>
    <svg
        class="mr-2 lg:mr-3 stroke-current hidden md:group-hover:block"
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.8125 6H13.1875"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M8.125 0.9375L13.1875 6L8.125 11.0625"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>
